import {
  querystoryCategories,
  detailstoryCategories,
  addstoryCategories,
  updatestoryCategories,
  updateOrderstoryCategories,
  detailstoryCategoriestree,
  updateStatusstoryCategories,
  querystoryCategoriesList
} from '@/services/storyCategoriesRedux';

export default {
  namespace: 'storyCategoriesModels',

  state: {
    dataAll: [],
    data: {
      list: [],
      pagination: {},
    },
    info: {},
    query: {},
    filter: {},
  },

  effects: {
    *filterUser({ payload }, { put }) {
      yield put({
        type: 'filter',
        payload,
      });
    },
    *fetch({ payload, callback }, { call, put }) {
      const response = yield call(querystoryCategories, payload);
      yield put({
        type: 'query',
        payload,
      });
      if (response) {
        yield put({
          type: 'save',
          payload: response || {},
        });
      }
      if (callback) callback(response);
    },
    *fetchLazyLoading({ payload, callback }, { call, put }) {
      const response = yield call(querystoryCategories, payload);
      if (response) {
        yield put({
          type: 'saveAll',
          payload: response || {},
        });
      }
      if (callback) callback(response)
    },
    *fetchListLazyLoading({ payload, callback }, { call, put }) {
      const response = yield call(querystoryCategoriesList, payload);
      if (response) {
        yield put({
          type: 'saveAll',
          payload: response || {},
        });
      }
      if (callback) callback(response)
    },
    *detailTree({ payload, callback }, { call }) {
      const response = yield call(detailstoryCategoriestree, payload);
      if (callback) callback(response)
    },
    *detail(
      {
        payload: { id },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(detailstoryCategories, id);
      if (response) {
        yield put({ type: 'info', payload: response || {} });
        if (callback) callback(response);
      }
    },
    *detailNoRedux(
      {
        payload: { id },
        callback,
      },
      { call }
    ) {
      const response = yield call(detailstoryCategories, id);
      if (response) {
        if (callback) callback(response);
      }
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(addstoryCategories, payload);
      if (callback) callback(response);

    },
    *update(
      {
        payload: { id, params },
        callback,
      },
      { call }
    ) {
      const response = yield call(updatestoryCategories, id, params);
      // console.log('dsada',params)
      if (callback) callback(response);
    },
    *updateOrder({ payload, callback }, { call }) {
      // console.log("updateOrder: ", payload)
      const responseUpdate = yield call(updateOrderstoryCategories, payload);
      if (responseUpdate) {
        if (callback) callback(responseUpdate);
      }
    },
    *updateStatus({ payload: { id, params }, callback }, { call }) {
      const response = yield call(updateStatusstoryCategories, id, params);
      if (callback) callback(response);
    },
  },

  reducers: {
    filter(state, action) {
      return {
        ...state,
        filter: action.payload,
      };
    },
    save(state, action) {
      return {
        ...state,
        data: action.payload.result,
        info: {}
      };
    },
    saveAll(state, action) {
      return {
        ...state,
        dataAll: action.payload && action.payload.result,
      };
    },
    removeAndsave(state, action) {
      return {
        ...state,
        data: {
          list: action.payload.list,
          pagination: {
            ...state.data.pagination,
            total: action.payload.pagination.total,
          },
        },
      };
    },
    info(state, action) {
      return {
        ...state,
        info: action.payload,
      };
    },
    query(state, action) {
      return {
        ...state,
        query: action.payload,
      };
    },
  },
};
