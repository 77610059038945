/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
// import log from '@/utils/log';


export default {
  namespace: 'actions',

  state: {
    visibleControlMobile: false,
    titleHeaderMobile: ''
  },
  effects: {
    *controlMenuMobile({ payload }, { put }) {
      yield put({ type: 'changeVisibleControl', payload: payload || false });
    },
    *changeTitleHeader({ payload }, { put }) {
      yield put({ type: 'changeTitle', payload: payload || '' });
    },
  },

  reducers: {
    changeVisibleControl(state, action) {
      return { ...state, visibleControlMobile: action.payload };
    },
    changeTitle(state, action) {
      return { ...state, titleHeaderMobile: action.payload };
    },
  },
};
