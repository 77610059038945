/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
import log from '@/utils/log';
import { querystoryChapters, createstoryChapters, infostoryChapters, updatestoryChapters, updateStatusChapters, removeChapters, querystoryChaptersNumber } from '@/services/storyChaptersRedux';

export default {
  namespace: 'storyChapters',

  state: {
    dataAll: [],
    data: {
      list: [],
      pagination: {},
    },
    info: {},
    query: {},
    queryFilter: {}
  },



  effects: {
    *filter({ payload }, { put }) {
      yield put({
        type: 'queryFilter',
        payload: payload || {}
      });
    },
    *fetch({ payload, callback }, { call, put }) {

      const response = yield call(querystoryChapters, payload);
      yield put({
        type: 'query',
        payload
      });
      if (response) {
        yield put({
          type: 'save',
          payload: response || {}
        });
      }
      if (callback) callback(response);

    },
    *fetchNumber({ payload, callback }, { call }) {
      const response = yield call(querystoryChaptersNumber, payload);
      if (callback) callback(response)
    },
    *fetchLazyLoading({ payload, callback }, { call }) {
      const response = yield call(querystoryChapters, payload);
      if (callback) callback(response)
    },
    *detail({ payload: { id }, callback }, { call, put }) {
      const response = yield call(infostoryChapters, id);
      // const page = yield select(state => state.users.page);
      if (callback) callback(response);
      if (response) {
        yield put({ type: 'info', payload: response || {} });
      }
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(createstoryChapters, payload);
      if (callback) callback(response);

    },
    *updateStatus({ payload: { id, params }, callback }, { call }) {
      const response = yield call(updateStatusChapters, id, params);
      if (callback) callback(response);
    },
    *update({ payload: { id, params }, callback }, { call }) {
      const response = yield call(updatestoryChapters, params, id);
      if (callback) callback(response);
    },
    *remove({ payload: { id }, callback }, { call, put }) {
      const response = yield call(removeChapters, id);
      if (callback) callback(response);

    },

  },

  reducers: {
    queryFilter(state, action) {
      return {
        ...state,
        queryFilter: action.payload
      }
    },
    save(state, action) {
      return {
        ...state,
        data: action.payload.result,
        info: {}
      }
    },
    removeAndsave(state, action) {
      return {
        ...state,
        data: {
          list: action.payload.list,
          pagination: {
            ...state.data.pagination,
            total: action.payload.pagination.total
          }
        },
      };
    },
    info(state, action) {
      return {
        ...state,
        info: action.payload,
      };
    },
    query(state, action) {
      return {
        ...state,
        query: action.payload,
      };
    },
    saveAll(state, action) {
      return {
        ...state,
        dataAll: action.payload && action.payload.result,
      };
    },
  },
};
