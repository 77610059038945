/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
import log from '@/utils/log';
import { querystories, createstories, infostories, updatestories } from '@/services/storiesRedux';

export default {
  namespace: 'stories',

  state: {
    dataAll: [],
    data: {
      list: [],
      pagination: {},
    },
    info: {},
    query: {},
    queryFilter: {}
  },



  effects: {
    *filter({ payload }, { put }) {
      yield put({
        type: 'queryFilter',
        payload: payload || {}
      });
    },
    *fetch({ payload, callback }, { call, put }) {

      const response = yield call(querystories, payload);
      yield put({
        type: 'query',
        payload
      });
      if (response) {
        yield put({
          type: 'save',
          payload: response || {}
        });
      }
      if (callback) callback(response);

    },
    *fetchLazyLoading({ payload, callback }, { call }) {
      const response = yield call(querystories, payload);
      if (callback) callback(response)
    },
    *detail({ payload: { id }, callback }, { call, put }) {
      const response = yield call(infostories, id);
      // const page = yield select(state => state.users.page);
      if (callback) callback(response);
      if (response) {
        yield put({ type: 'info', payload: response || {} });
      }
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(createstories, payload);
      if (callback) callback(response);

    },
    *updateStatus({ payload: { id, params, filter }, callback }, { call, put }) {
      log('updateStatus params ', params, params.filter)
      const response1 = yield call(updatestories, params, id);
      if (response1) {
        const tParams = {
          filter: JSON.stringify(params.filter),
          range: JSON.stringify([0, 9]),
          sort: JSON.stringify(["name", "ASC"])
          // range: JSON.stringify([1,5])
        }
        log('updateStatus tParams ', tParams)
        const response = yield call(querystories, tParams);
        yield put({
          type: 'query',
          filter
        });
        if (response) {
          yield put({
            type: 'save',
            payload: response || {}
          });
        }
        if (callback) callback(response1);
      }



      // if (response) {
      //   yield put({ type: 'info', payload: response || {} });
      // }

      // if (callback) callback(response);
    },
    *update({ payload: { id, params }, callback }, { call }) {
      const response = yield call(updatestories, params, id);
      if (callback) callback(response);
    },

  },

  reducers: {
    queryFilter(state, action) {
      return {
        ...state,
        queryFilter: action.payload
      }
    },
    save(state, action) {
      return {
        ...state,
        data: action.payload.result,
        info: {}
      }
    },
    removeAndsave(state, action) {
      return {
        ...state,
        data: {
          list: action.payload.list,
          pagination: {
            ...state.data.pagination,
            total: action.payload.pagination.total
          }
        },
      };
    },
    info(state, action) {
      return {
        ...state,
        info: action.payload,
      };
    },
    query(state, action) {
      return {
        ...state,
        query: action.payload,
      };
    },
    saveAll(state, action) {
      return {
        ...state,
        dataAll: action.payload && action.payload.result,
      };
    },
  },
};
