// import { stringify } from 'qs';
import { fakeAccountLogin, socialAccountLogin, queryCurrent, changepass, updateUser, registerUser, requestForgetPassUser, verifyToken, queryStoryConverters } from '@/services/accountRedux';
import { setAuthority } from '@/utils/authority';
// import { getPageQuery } from '../utils/utils';
import { reloadAuthorized } from '@/utils/Authorized';
import { login, clearLogout } from '@/utils/auth';
// import { encryptedString } from '@/utils/crypto';

export default {
  namespace: 'login',

  state: {
    status: undefined,
    currentUser: {},
    dataImage: {},
  },

  effects: {
    *uploadImage({ payload }, { put }) {
      yield put({
        type: 'saveImage',
        payload,
      });

    },
    *fetchCurrent({ payload }, { call, put }) {
      let response;
      if (payload) {
        const { cookies } = payload;
        const headers = {
          'X-Auth-Email': `${cookies.auth_email}`,
          'X-Auth-Key': `${cookies.token}`,
        }
        response = yield call(queryCurrent, headers);
        // console.log(response);
      } else {
        response = yield call(queryCurrent);
      }
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
    *fetchStoryConverterst({ callback }, { call, put }) {
      const response = yield call(queryStoryConverters);
      if (callback) callback(response);
    },
    *login({ payload, callback, redirect }, { call, put }) {
      // console.log("model login payload: %o", payload);
      // console.log("login", payload)
      const response = yield call(fakeAccountLogin, payload);
      if (response) {
        yield put({
          type: 'changeLoginStatus',
          payload: response,
        });
        // Login successfully
        if (response.status === 'ok') {
          login({ token: response.token || '', email: response.user, rememberMe: payload.rememberMe, redirect })

        }
        else if (callback) callback({ ...response });
      }
    },

    *changepass({ payload: { id, params }, callback }, { call }) {
      const responseReset = yield call(changepass, id, params);
      if (callback) callback(responseReset);

    },
    *updateInfo({ payload: { id, params }, callback }, { call }) {

      const response = yield call(updateUser, id, params);

      if (callback) callback(response);
    },

    *logout({ payload, callback }, { put }) {
      reloadAuthorized();
      clearLogout();
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: false,
          currentAuthority: 'guest',
        },
      });
      if (callback) callback({ payload })
    },

    *loginSocial({ payload, callback, redirect }, { call, put }) {
      const response = yield call(socialAccountLogin, payload);
      if (response) {
        yield put({
          type: 'changeLoginStatus',
          payload: response,
        });
        // Login successfully
        if (response.status === 'ok') {
          login({ token: response.token || '', email: response.user, redirect })

        }
        else if (callback) callback({ ...response });
      }
    },
    *register({ payload, callback }, { call }) {
      const response = yield call(registerUser, payload);
      if (callback) callback(response);
    },
    *requestForgetPass({ payload, callback }, { call }) {
      const response = yield call(requestForgetPassUser, payload);
      if (callback) callback(response);
    },
    *verifyToken({ payload }, { call, put }) {
      const response = yield call(verifyToken, payload);
      if (response) {
        yield put({ type: 'saveVerifyToken', payload: response || {} });
      }
    },
  },

  reducers: {
    saveImage(state, { payload }) {
      return {
        ...state,
        dataImage: payload,
      };
    },
    changeLoginStatus(state, { payload }) {
      setAuthority(payload);
      return {
        ...state,
        status: payload.status,
        type: payload.type,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
  },
};
