import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';
// import log from '@/utils/log';

// user
// eslint-disable-next-line import/prefer-default-export
export async function queryMenus(params) {
  const { filter, sort, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'ASC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/menus/find/all/parent-child?${stringify(query)}`);
}

export async function queryCategoryInfo(id) {
  return request(`${CONFIG.API_SERVER_WEB}/categories/${id}`);
}
export async function queryCategoryInfoByName(name) {
  // console.log(`${CONFIG.API_SERVER_WEB}/categories/${name}`);

  return request(`${CONFIG.API_SERVER_WEB}/categories/${name}`);
}
export async function queryCategoryAll(params) {
  const { filter, sort, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/categories?${stringify(query)}`);
  return request(`${CONFIG.API_SERVER_WEB}/categories?${stringify(query)}`);
}

export async function queryArticleAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/articles?${stringify(query)}`);
}

export async function queryArticlesDetail(params) {
  // console.log(`${CONFIG.API_SERVER_WEB}/articles/${params.id}`);
  return request(`${CONFIG.API_SERVER_WEB}/articles/${params.id}`);
}
export async function queryProvinceDetail(params) {
  return request(`${CONFIG.API_SERVER_WEB}/provinces/${params.id}`);
}
export async function querycountries(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/countries/getlist/countriesFilterVisa?${stringify(query)}`);
}
export async function queryProvinces(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['provinceName', 'ASC']),
    range: JSON.stringify(range || [0, 20]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/provinces/getlist/provinces?${stringify(query)}`);
}
export async function queryCatalogArticle(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 20]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/articles/getlist/articles?${stringify(query)}`);
}
export async function queryAd(params) {
  const { filter, attributes, sort } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['orderBy', 'ASC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/ads?${stringify(query)}`);
}

export async function queryArticleInfoByName(payload) {
  const { name, attributes } = payload;
  return request(`${CONFIG.API_SERVER_WEB}/articles/${name}${attributes || ''}`);
}

export async function queryWebInfo(id) {
  return request(`${CONFIG.API_SERVER_WEB}/sites/${id}`);
}
export async function queryChildrenCategoryAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }

  return request(`${CONFIG.API_SERVER_WEB}/categories/find/list/parent-child?${stringify(query)}`);
}
export async function queryTreeCategoryById(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(
    `${CONFIG.API_SERVER_WEB}/categories/find/getbycategories/parent-child?${stringify(query)}`
  );
}
export async function queryArticleInfo(payload) {
  const { id, attributes } = payload;
  /* const { filter, sort } = params;
    const query = {
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort || )
    }; */
  // console.log(`${CONFIG.API_SERVER_WEB}/categories/${id}`)
  return request(`${CONFIG.API_SERVER_WEB}/articles/${id}${attributes || ''}`);
}
export async function queryDataSiteUrl(params) {
  const { filter, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/sites?${stringify(query)}`);
  return request(`${CONFIG.API_SERVER_WEB}/sites?${stringify(query)}`);
}


export async function queryStoryCategories(params, token) {
  return request(`${CONFIG.API_SERVER_WEB}/storyCategories?${stringify(params)}`, {}, token);
}

export async function queryNewViewStories(params, token) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserView?${stringify(params)}`, {}, token);
}
export async function queryNewViewStoriesStart(params, token) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserView/start?${stringify(params)}`, {}, token);
}

export async function queryStoryFollow(params, token) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserFollow?${stringify(params)}`, {}, token);
}
export async function queryStoryFollowStart(params, token) {
  // console.log(`${CONFIG.API_SERVER_WEB}/storyUserFollow/start?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_WEB}/storyUserFollow/start?${stringify(params)}`, {}, token);
}

export async function queryStories(params) {
  // console.log(`${CONFIG.API_SERVER_WEB}/stories?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_WEB}/stories?${stringify(params)}`);
}

export async function queryTypeStory(params) {
  return request(`${CONFIG.API_SERVER_WEB}/storyTypes?${stringify(params)}`);
}

export async function queryReview(params, token) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserStar?${stringify(params)}`, {}, token);
}

export async function queryTagStories(params) {
  return request(`${CONFIG.API_SERVER_WEB}/storyTags?${stringify(params)}`);
}

export async function queryDetailChapter(params, token) {
  // console.log(`${CONFIG.API_SERVER_WEB}/storyChapters/byUrl/${params.storiesId}/${params.storySectionsNumber}/${params.storyChaptersNumber}`);
  return request(`${CONFIG.API_SERVER_WEB}/storyChapters/byUrl/${params.storiesId}/${params.storySectionsNumber}/${params.storyChaptersNumber}`, {}, token);
}
export async function queryDetailStory({ id, }, token) {
  // console.log(`${CONFIG.API_SERVER_WEB}/stories/${id}`, {}, token);
  return request(`${CONFIG.API_SERVER_WEB}/stories/${id}`, {}, token);
}
export async function queryComment(params) {
  // console.log(`${CONFIG.API_SERVER_WEB}/storyComments/find/list/parent-child?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_WEB}/storyComments/find/list/parent-child?${stringify(params)}`);
}

export async function queryChapter(params) {
  // console.log(`${CONFIG.API_SERVER_WEB}/storyChapters/sort?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_WEB}/storyChapters/sort?${stringify(params)}`);
}

export async function queryLikeReview(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserLike`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryDeleteLike(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserLike`, {
    method: "DELETE",
    body: { ...query }
  })
}

export async function queryCreateComment(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyComments`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryFollowStory(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserFollow`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryNominateStory(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserNominate`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryIncreaseViewStory(query) {
  return request(`${CONFIG.API_SERVER_WEB}/stories/upView/${query.id}`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryReviewStory(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserStar/createOrUpdate`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryDeleteReviewStory({ id }) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserStar/${id}`, {
    method: "DELETE",
  })
}

export async function queryDeleteNominateStory(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserNominate`, {
    method: "DELETE",
    body: { ...query }
  })
}

export async function queryDeleteFollowStory(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserFollow`, {
    method: "DELETE",
    body: { ...query }
  })
}

export async function queryMultiSortStoryHome(params) {
  // console.log('api', `${CONFIG.API_SERVER_WEB}/stories/start?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_WEB}/stories/start?${stringify(params)}`);
}

export async function queryListConverters(params) {
  return request(`${CONFIG.API_SERVER_WEB}/storyConverters?${stringify(params)}`);
}

export async function queryDetailConverters({ id }) {
  return request(`${CONFIG.API_SERVER_WEB}/storyConverters/${id}`);
}

export async function queryDetailAuthor({ id }) {
  return request(`${CONFIG.API_SERVER_WEB}/storyAuthors/${id}`);
}

export async function queryTopAuthor(params) {
  return request(`${CONFIG.API_SERVER_WEB}/storyAuthors/sort?${stringify(params)}`);
}

export async function queryListStartHome(params, typeApi) {
  return request(`${CONFIG.API_SERVER_WEB}/stories/start/${typeApi}?${stringify(params)}`);
}

export async function queryCreateViewHistory(query) {
  return request(`${CONFIG.API_SERVER_WEB}/storyUserView`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryCreateFeedBackChapter(query) {
  return request(`${CONFIG.API_SERVER_WEB}/feedbacks`, {
    method: "POST",
    body: { ...query }
  })
}

