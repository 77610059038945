import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';

export async function querystorySections(params) {
  // log('storySectionsRedux ',`${CONFIG.API_ENDPOINT}/api/c/storySections?${stringify(params)}`)

  return request(`${CONFIG.API_ENDPOINT}/api/c/storySections?${stringify(params)}`);
}

export async function createstorySections(params) {

  return request(`${CONFIG.API_ENDPOINT}/api/c/storySections`, {
    method: 'POST',
    body: {
      ...params
    }
    ,
  });
}

export async function infostorySections(id) {
  return request(`${CONFIG.API_ENDPOINT}/api/c/storySections/${id}`, {
    method: 'GET',
  });
}

export async function updatestorySections(params, id) {
  // log("url values:", CONFIG.API_ENDPOINT_2 + "/storySectionss/"+ id)
  return request(`${CONFIG.API_ENDPOINT}/api/c/storySections/${id}`, {
    method: 'PUT',
    body: {
      ...params,
      method: 'UPDATE'
    }
    ,
  });
}


export async function getAllstorySections(params) {
  const { filter, sort } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ["name", "ASC"]),
  };
  return request(`${CONFIG.API_ENDPOINT}/api/c/storySections/get/all?${stringify(query)}`);
}