import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';

export async function querystories(params) {

  return request(`${CONFIG.API_ENDPOINT}/api/c/stories?${stringify(params)}`);
}
export async function querystoriesChapters(params) {
  // log('storiesRedux ',`${CONFIG.API_ENDPOINT}/api/c/stories?${stringify(params)}`)

  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters/sort?${stringify(params)}`);
}

export async function createstories(params) {

  return request(`${CONFIG.API_ENDPOINT}/api/c/stories`, {
    method: 'POST',
    body: {
      ...params
    }
    ,
  });
}

export async function infostories(id) {
  // log('stories Redux',`${CONFIG.API_ENDPOINT}/api/c/stories/${id}` )
  return request(`${CONFIG.API_ENDPOINT}/api/c/stories/${id}`, {
    method: 'GET',
  });
}

export async function updatestories(params, id) {
  // log("url values:", CONFIG.API_ENDPOINT_2 + "/storiess/"+ id)
  return request(`${CONFIG.API_ENDPOINT}/api/c/stories/${id}`, {
    method: 'PUT',
    body: {
      ...params,
      method: 'UPDATE'
    }
    ,
  });
}


export async function getAllstories(params) {
  const { filter, sort } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ["name", "ASC"]),
  };
  return request(`${CONFIG.API_ENDPOINT}/api/c/stories/get/all?${stringify(query)}`);
}