import websModels from './websModels';
import actions from './actions';
import loginModels from './loginModels'
import storyAuthorsModels from './storyAuthorsModels'
import storyTagsModels from './storyTagsModels'
import storyTypesModels from './storyTypesModels';
import storyCategoriesModels from './storyCategoriesModels'
import storiesModels from './storiesModels'
import storyChaptersModels from './storyChaptersModels'
import storySectionsModels from './storySectionsModels'

const model = [
  storySectionsModels,
  storyChaptersModels,
  storiesModels,
  storyCategoriesModels,
  storyAuthorsModels,
  storyTagsModels,
  storyTypesModels,
  loginModels,
  websModels,
  actions,
];

export default model;