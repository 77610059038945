/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
// import log from '@/utils/log';
import { querystoryTypes, detailstoryTypes, addstoryTypes, updatestoryTypes, querystoryTypeAll, updateStatusstoryTypes } from '@/services/storyTypeRedux';

export default {
  namespace: 'storyTypes',

  state: {
    dataAll: [],
    data: {
      list: [],
      pagination: {},
    },
    info: {},
    query: {},
    filter: {}
  },

  effects: {
    *filterUser({ payload }, { put }) {
      yield put({
        type: 'filter',
        payload
      });
    },
    *fetch({ payload, callback }, { call, put }) {

      const response = yield call(querystoryTypes, payload);
      yield put({
        type: 'query',
        payload
      });
      if (response) {
        yield put({
          type: 'save',
          payload: response || {}
        });
      }
      if (callback) callback(response)
      /* const { page } = payload;
      const { total = 0, page_size = DEFAULT_PAGE_SIZE, data = [] } = response;
      const responseData = {
        list: data,
        pagination: { page, page_size, total }
      };
      yield put({
        type: 'save',
        payload: responseData,
      }); */
    },
    *fetchLazyLoading({ payload, callback }, { call }) {
      const response = yield call(querystoryTypes, payload);
      if (callback) callback(response)
    },
    *detail({ payload: { id }, callback }, { call, put }) {
      const response = yield call(detailstoryTypes, id);
      // const page = yield select(state => state.users.page);
      if (callback) callback(response);
      if (response) {
        yield put({ type: 'info', payload: response || {} });
      }
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(addstoryTypes, payload);
      if (callback) callback(response);

    },

    *updateStatus({ payload: { id, params }, callback }, { call }) {
      const response = yield call(updateStatusstoryTypes, id, params);
      if (callback) callback(response);
    },
    *update({ payload: { id, params }, callback }, { call }) {
      const response = yield call(updatestoryTypes, id, params);
      if (callback) callback(response);
    },
  },

  reducers: {
    filter(state, action) {
      return {
        ...state,
        filter: action.payload
      }
    },
    save(state, action) {
      return {
        ...state,
        data: action.payload.result,
        info: {}
      }
    },
    saveAll(state, action) {
      return {
        ...state,
        dataAll: action.payload && action.payload.result,
      };
    },
    removeAndsave(state, action) {
      return {
        ...state,
        data: {
          list: action.payload.list,
          pagination: {
            ...state.data.pagination,
            total: action.payload.pagination.total
          }
        },
      };
    },
    info(state, action) {
      return {
        ...state,
        info: action.payload,
      };
    },
    query(state, action) {
      return {
        ...state,
        query: action.payload,
      };
    },
  },
};
