import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';
// import log from '@/utils/log';

// user
export async function querystoryCategories(params) {
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyCategories/find/list/parent-child?${stringify(params)}`);
}


export async function querystoryCategoriesList(params) {
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyCategories?${stringify(params)}`);
}
export function detailstoryCategories(id) {
  // const urlRequest = `${API_ENDPOINT}/companies/${id}`;
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyCategories/${id}`, {
    method: 'GET',
  });
}

export function detailstoryCategoriestree(params) {
  const query = {
    filter: JSON.stringify(params),
  };
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyCategories/find/list/parent-child-one?${stringify(query)}`);
}

export async function removestoryCategories(id) {
  // console.log("id", id)
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyCategories/${id}`, {
    method: 'DELETE',
  });
}

export async function addstoryCategories(params) {
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyCategories`, {
    method: 'POST',
    body: {
      ...params,
      method: 'post',
    },
  });
}

export async function updatestoryCategories(id, params) {
  // console.log("dadsa", params)
  // const urlRequest = `${API_ENDPOINT}/companies/${id}`;
  const urlRequest = `${CONFIG.API_ENDPOINT}/api/c/storyCategories/${id}`;
  return request(urlRequest, {
    method: 'PUT',
    body: {
      ...params,
      method: 'update',
    },
  });
}

export async function updateOrderstoryCategories(params) {
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyCategories/update/orders`, {
    method: 'PUT',
    body: {
      ...params,
    },
  });
}


export async function updateStatusstoryCategories(id, params) {
  // const urlRequest = `${API_ENDPOINT}/companies/${id}`;
  const urlRequest = `${CONFIG.API_ENDPOINT}/api/c/storyCategories/update-status/${id}`;
  return request(urlRequest, {
    method: 'PUT',
    body: {
      ...params,
      method: 'update',
    },
  });
}