import publicRuntimeConfig from '@/utils/config'
import request from '../utils/request';

export async function fakeAccountLogin(params) {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/authenticate`, {
        method: 'POST',
        body: params,
    });
}

export async function socialAccountLogin(params) {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/web/c/users/loginWithSocial`, {
        method: 'POST',
        body: params,
    });
}

export async function verify(params) {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/verify`, {
        method: 'POST',
        body: params,
    });
}

export async function fakeRegister(params) {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/signup`, {
        method: 'POST',
        body: params,
    });
}

export async function queryDashboard() {
    return request(`${publicRuntimeConfig.API_SERVER}/siteUsageLogs`);
}

export async function queryCurrent(headers) {
    if (headers)
        return request(`${publicRuntimeConfig.API_ENDPOINT}/api/c/currentUser`, {
            headers
        });
    return request(`${publicRuntimeConfig.API_ENDPOINT}/api/c/currentUser`)
}
export async function queryStoryConverters() {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/api/c/storyConverters/me`)
}
export function changepass(id, params) {
    // const urlRequest = `${API_ENDPOINT}/companies/${id}`;
    return request(`${publicRuntimeConfig.API_ENDPOINT}/api/c/userspass/changepass/${id}`, {
        method: 'POST',
        body: {
            ...params,
            method: 'post',
        },
    });
}
export async function updateUser(id, params) {
    // const urlRequest = `${API_ENDPOINT}/companies/${id}`;
    const urlRequest = `${publicRuntimeConfig.API_ENDPOINT}/api/c/users/${id}`;
    return request(urlRequest, {
        method: 'PUT',
        body: {
            ...params,
            method: 'update',
        },
    });
}
export async function registerUser(params) {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/web/c/users/register`, {
        method: 'POST',
        body: {
            ...params,
            method: 'post',
        },
    });
}

export async function requestForgetPassUser(params) {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/web/c/users/requestForgetPass`, {
        method: 'POST',
        body: {
            ...params,
            method: 'post',
        },
    });
}

export async function verifyToken(params) {
    return request(`${publicRuntimeConfig.API_ENDPOINT}/web/c/token/verifyToken?token=${params.token}`, {
        method: 'GET',
    });
}