import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';

export async function querystoryChapters(params) {
  // log('storyChaptersRedux ',`${CONFIG.API_ENDPOINT}/api/c/storyChapters?${stringify(params)}`)

  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters?${stringify(params)}`);
}

export async function createstoryChapters(params) {

  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters`, {
    method: 'POST',
    body: {
      ...params
    }
    ,
  });
}

export async function infostoryChapters(id) {
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters/${id}`, {
    method: 'GET',
  });
}
export async function querystoryChaptersNumber(params) {
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters/newChapter?${stringify(params)}`, {
  });
}

export async function updatestoryChapters(params, id) {
  // log("url values:", CONFIG.API_ENDPOINT_2 + "/storyChapterss/"+ id)
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters/${id}`, {
    method: 'PUT',
    body: {
      ...params,
      method: 'UPDATE'
    }
    ,
  });
}


export async function getAllstoryChapters(params) {
  const { filter, sort } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ["name", "ASC"]),
  };
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters/get/all?${stringify(query)}`);
}

export async function updateStatusChapters(id, params) {
  // const urlRequest = `${API_ENDPOINT}/companies/${id}`;
  const urlRequest = `${CONFIG.API_ENDPOINT}/api/c/storyChapters/update-status/${id}`;
  return request(urlRequest, {
    method: 'PUT',
    body: {
      ...params,
      method: 'update',
    },
  });
}

export async function removeChapters(id) {
  // console.log("id", id)
  return request(`${CONFIG.API_ENDPOINT}/api/c/storyChapters/${id}`, {
    method: 'DELETE',
  });
}